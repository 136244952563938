import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton, TextField, Container, Divider, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getData, postData } from "../../ServerRequest"; 
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export default function ProductDetail() { 
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get('product_id');
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [editingImageUrl, setEditingImageUrl] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [images, setImages] = useState([]);
    const [formData, setFormData] = useState({ 
        ps_product_ID: '',
        ps_product_productcode: '',
        ps_product_cat: '',
        ps_product_productdesc: '',
        ps_product_productimg: '',
        ps_product_prodwho: '',
        ps_product_prodname: '',
        ps_product_desc: '',
        ps_product_sellrate1: '',
        ps_product_sellrate2: '',
        ps_product_sellrate3: '',
        ps_product_subcat: '',
        ps_product_mill: '',
        ps_product_count: '',
        ps_product_composition: '',
        ps_product_size: '',
        ps_product_construction: '',
        ps_product_width: '',
        ps_product_style: '',
        ps_product_quality: '',
        ps_product_type: '',
        ps_product_pattern: '',
        ps_product_shade: '',
        ps_product_color1: '',
        ps_product_color2: '',
        ps_product_color3: '',
        ps_product_color4: '',
        ps_product_color5: '',
        ps_product_purchaserate1: '',
        ps_product_purchaserate2: '',
        ps_product_purchaserate3: '',
        ps_product_createdttime: '',
        ps_product_modifydttime: '',
        ps_product_QQH: '',
        ps_product_SO: '',
        ps_product_IQ: '',
        ps_product_DC: '',
        ps_product_DCvalue: '',
        ps_product_DCqty: '',
        ps_product_rcptqty: '',
        ps_product_issueqty: '',
        ps_product_lastsellrate: '',
        ps_product_COMM: '',
        ps_product_netqty: '',
        ps_product_dim1: '',
        ps_product_dim2: '',
        ps_product_dim3: '',
        ps_product_dim4: '',
        ps_product_dim5: '',
        ps_product_instock: '',
        ps_product_likes: '',
        ps_product_tags: '',
        ps_product_tags2: '',
        ps_product_excelpath: '',
        ps_product_isimgdone: '',
        ps_product_tags3: '',
    });

    const handleProductSubmit = async (event) => {
        console.log(formData);
        setLoading(true);
        event.preventDefault();
        let productReqData = {
            'ps_product_ID': formData.ps_product_ID ? formData.ps_product_ID : '',
            'ps_product_productcode': formData.ps_product_productcode,
            'ps_product_cat': formData.ps_product_cat,
            'ps_product_productdesc': formData.ps_product_productdesc,
            'ps_product_productimg': formData.ps_product_productimg,
            'ps_product_prodwho': formData.ps_product_prodwho,
            'ps_product_prodname': formData.ps_product_prodname,
            'ps_product_desc': formData.ps_product_desc,
            'ps_product_sellrate1': formData.ps_product_sellrate1,
            'ps_product_sellrate2': formData.ps_product_sellrate2,
            'ps_product_sellrate3': formData.ps_product_sellrate3,
            'ps_product_subcat': formData.ps_product_subcat,
            'ps_product_mill': formData.ps_product_mill,
            'ps_product_count': formData.ps_product_count,
            'ps_product_composition': formData.ps_product_composition,
            'ps_product_size': formData.ps_product_size,
            'ps_product_construction': formData.ps_product_construction,
            'ps_product_width': formData.ps_product_width,
            'ps_product_style': formData.ps_product_style,
            'ps_product_quality': formData.ps_product_quality,
            'ps_product_type': formData.ps_product_type,
            'ps_product_pattern': formData.ps_product_pattern,
            'ps_product_shade': formData.ps_product_shade,
            'ps_product_color1': formData.ps_product_color1,
            'ps_product_color2': formData.ps_product_color2,
            'ps_product_color3': formData.ps_product_color3,
            'ps_product_color4': formData.ps_product_color4,
            'ps_product_color5': formData.ps_product_color5,
            'ps_product_purchaserate1': formData.ps_product_purchaserate1,
            'ps_product_purchaserate2': formData.ps_product_purchaserate2,
            'ps_product_purchaserate3': formData.ps_product_purchaserate3,
            'ps_product_createdttime': formData.ps_product_createdttime,
            'ps_product_modifydttime': formData.ps_product_modifydttime,
            'ps_product_QQH': formData.ps_product_QQH,
            'ps_product_SO': formData.ps_product_SO,
            'ps_product_IQ': formData.ps_product_IQ,
            'ps_product_DC': formData.ps_product_DC,
            'ps_product_DCvalue': formData.ps_product_DCvalue,
            'ps_product_DCqty': formData.ps_product_DCqty,
            'ps_product_rcptqty': formData.ps_product_rcptqty,
            'ps_product_issueqty': formData.ps_product_issueqty,
            'ps_product_lastsellrate': formData.ps_product_lastsellrate,
            'ps_product_COMM': formData.ps_product_COMM,
            'ps_product_netqty': formData.ps_product_netqty,
            'ps_product_dim1': formData.ps_product_dim1,
            'ps_product_dim2': formData.ps_product_dim2,
            'ps_product_dim3': formData.ps_product_dim3,
            'ps_product_dim4': formData.ps_product_dim4,
            'ps_product_dim5': formData.ps_product_dim5,
            'ps_product_instock': formData.ps_product_instock,
            'ps_product_likes': formData.ps_product_likes,
            'ps_product_tags': formData.ps_product_tags,
            'ps_product_tags2': formData.ps_product_tags2,
            'ps_product_excelpath': formData.ps_product_excelpath,
            'ps_product_isimgdone': formData.ps_product_isimgdone,
            'ps_product_tags3': formData.ps_product_tags3,
        };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        if (formData.ps_product_productimg && formData.ps_product_productimg instanceof Blob) {
            productReqData.ps_product_productimg = await convertToBase64(formData.ps_product_productimg);
        } else if (isEditing && editingImageUrl) {
            productReqData.ps_product_productimg = editingImageUrl;
        }


        console.log(productReqData);
    
        try {
            let productResData = await postData('/api/product/all', productReqData);
            if (productResData['data']['code'] === '200') {
                alert('Product saved successfully.');
                if (isEditing) {
                    setImages(images.map(image => image.id === selectedImage.id ? formData : image));
                } else {
                    const newimage = { ...formData, id: images.length + 1 };
                    setImages([...images, newimage]);
                }            
                setLoading(false);
            } else {
                alert('Error saving product. Please try again.');
            }
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };   

    useEffect(() => {
        async function fetchformData() {
            let resData = await getData('/api/product/get-product?product_id=' + productId);
            console.log(resData);
            let product_data = resData['data']['product']
            if (product_data) {
                console.log(product_data);
                if (product_data) {
                    setFormData(product_data);
                    console.log(formData)
                }
            } else {
                console.error("Error fetching product data:", resData.message);
            }
            setLoading(false);
        }
        fetchformData();
    }, []);

    const handleToggleEdit = () => {
        setIsEditing((prev) => !prev);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const newImage = {
                    id: Date.now(), 
                    ps_product_productimg: reader.result, 
                };
                setImages((prevImages) => [...prevImages, newImage]); 
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', fontFamily: '"Roboto Condensed", sans-serif', marginLeft: 8 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: 3, p: 1 }}>
                <Typography variant="h6" sx={{ ml: 2 }}>Product ID : {formData.ps_product_ID}</Typography>
                <IconButton sx={{background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } }} onClick={isEditing ? handleProductSubmit : handleToggleEdit }>
                    {isEditing ? <SaveOutlinedIcon /> : <EditOutlinedIcon />} 
                </IconButton>
            </Box>                        
            <Box sx={{ p: 3, backgroundColor: '#eceff1', boxShadow: 3 }}>                                 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>    
                    <TextField fullWidth label= "CODE" value={formData.ps_product_productcode} onChange={handleInputChange} disabled variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "CATEGORY" value={formData.ps_product_cat} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PRODUCT DESCRIPTION" value={formData.ps_product_productdesc} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "TAGS 3" value={formData.ps_product_tags3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "WHO" value={formData.ps_product_prodwho} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PRODUCT NAME" value={formData.ps_product_prodname} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "DESCRIPTION" value={formData.ps_product_desc} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SELL RATE 1" value={formData.ps_product_sellrate1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SELL RATE 2" value={formData.ps_product_sellrate2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "SELL RATE 3" value={formData.ps_product_sellrate3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SUB CATEGORY" value={formData.ps_product_subcat} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "MILL" value={formData.ps_product_mill} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "COUNT" value={formData.ps_product_count} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COMPOSITION" value={formData.ps_product_composition} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "SIZE" value={formData.ps_product_size} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "CONSTRUCTION" value={formData.ps_product_construction} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "WIDTH" value={formData.ps_product_width} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "STYLE" value={formData.ps_product_style} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "QUALITY" value={formData.ps_product_quality} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "TYPE" value={formData.ps_product_type} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PATTERN" value={formData.ps_product_pattern} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "SHADE" value={formData.ps_product_shade} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 1" value={formData.ps_product_color1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 2" value={formData.ps_product_color2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "COLOR 3" value={formData.ps_product_color3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 4" value={formData.ps_product_color4} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COLOR 5" value={formData.ps_product_color5} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "PURCHASE RATE 1" value={formData.ps_product_purchaserate1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PURCHASE RATE 2" value={formData.ps_product_purchaserate2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "PURCHASE RATE 3" value={formData.ps_product_purchaserate3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "CREATED TIME" value={formData.ps_product_createdttime} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "MODIFIED TIME" value={formData.ps_product_modifydttime} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "QQH" value={formData.ps_product_QQH} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "SO" value={formData.ps_product_SO} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "IQ" value={formData.ps_product_IQ} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DC" value={formData.ps_product_DC} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "DC VALUE" value={formData.ps_product_DCvalue} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DC QUANTITY" value={formData.ps_product_DCqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "RCPT QUANTITY" value={formData.ps_product_rcptqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "ISSUE QUANTITY" value={formData.ps_product_issueqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "LAST SELL RATE" value={formData.ps_product_lastsellrate} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COMM" value={formData.ps_product_COMM} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "NET QUANTITY" value={formData.ps_product_netqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 1" value={formData.ps_product_dim1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 2" value={formData.ps_product_dim2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "DIM 3" value={formData.ps_product_dim3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 4" value={formData.ps_product_dim4} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DIM 5" value={formData.ps_product_dim5} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "IN STOCK" value={formData.ps_product_instock} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "LIKES" value={formData.ps_product_likes} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "TAGS" value={formData.ps_product_tags} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "TAGS 2" value={formData.ps_product_tags2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "EXCEL PATH" value={formData.ps_product_excelpath} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <FormControl fullWidth> 
                        <InputLabel shrink={true}>IS IMG DONE</InputLabel>
                        <Select fullWidth label= "IS IMG DONE" value={formData.ps_product_isimgdone} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ mt: 2, backgroundColor: 'white' }} >
                            <MenuItem value={0}>True</MenuItem>     
                            <MenuItem value={1}>False</MenuItem>    
                        </Select>
                    </FormControl>                         
                </Box>  
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "IMAGE" value={formData.ps_product_productimg} onChange={handleInputChange} disabled variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                                                
                    <IconButton component="label" sx={{ color: 'black', mt: 1 }}>
                        <FileUploadOutlinedIcon />
                        <input hidden accept="image/*" type="file" onChange={(e) => handleImageUpload(e)} />
                    </IconButton>
                </Box>  
                <Box sx={{ backgroundColor: 'white', padding: 4, borderRadius: 1 }}>
                    <Box margin="normal" sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: 1 }}>
                    {images.length > 0 ? (
                        images.map((image, index) => (                           
                            <Box key={image.id}sx={{ textAlign: 'center' }}>                              
                                <img src={image.ps_product_productimg} alt="Uploaded image" style={{ width: '140px', height: '60px', marginBottom: '10px', marginTop: '10px' }} />                                                         
                            </Box>                           
                        ))
                    ) : (
                        <PhotoCameraIcon fontSize="large" />
                    )}
                    </Box>
                </Box>                                         
            </Box>            
            <Divider sx={{ marginTop: 2 }} />
        </Container>
    );  
}
