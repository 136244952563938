import React, { useState } from 'react';
import { Typography, Box, Divider, TextField, Button, IconButton } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import UpdateIcon from '@mui/icons-material/Update';

// Register Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// Example data for the chart
const sampleData = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
];

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };

export default function UpdateLiveData() {
    const [data, setData] = useState(sampleData);
    const [inputValue, setInputValue] = useState('');

    const handleUpdate = () => {
        const newData = data.map((item) => ({
            ...item,
            uv: item.uv + (Math.random() * 1000 - 500),
        }));
        setData(newData);
    };

    // Transform data for Chart.js
    const chartData = {
        labels: data.map(item => item.name),
        datasets: [
            {
                label: 'UV',
                data: data.map(item => item.uv),
                borderColor: '#8884d8',
                backgroundColor: 'rgba(136, 132, 216, 0.2)',
                fill: true,
            },
            {
                label: 'PV',
                data: data.map(item => item.pv),
                borderColor: '#82ca9d',
                backgroundColor: 'rgba(130, 202, 157, 0.2)',
                fill: true,
            },
        ],
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', marginLeft: 11, marginTop: 0 }}>            
            <Box sx={{ padding: 2, boxShadow: 3 }}>
                <Typography variant="h6">
                    Update Live Data
                </Typography>
            </Box>                                  
            <Box sx={{ boxShadow: 3, backgroundColor: '#ECEFF1', p: 2 }}>                     
                <Typography variant="h7">
                    Update Data
                </Typography>
                <TextField fullWidth label="Enter New Value" variant="outlined" margin="normal" value={inputValue} onChange={(e) => setInputValue(e.target.value)} sx={{ fontSize: '0.875rem', '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <IconButton variant="contained" sx={buttonStyle} onClick={handleUpdate}>
                        <UpdateIcon /> 
                    </IconButton>
                </Box>
                <Divider sx={{ margin: 2 }} />                          
                <Typography variant="h6" sx={{ padding: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} marginBottom={2}>
                    Data Visualization
                </Typography>
                <Line data={chartData} />                        
            </Box>            
        </Box>
    );
};
