import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, IconButton, TextField, MenuItem, Divider } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };

export default function LandingPageFeatured(selectionId) {
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({ id: '', featured_section: '', featured_product_image: '', featured_name: '', link_page: '', orientation_number: '' });
    const [productTiles, setProductTiles] = useState([]);                 
    const [editingIndex, setEditingIndex] = useState(null); 
    const [isVisible, setIsVisible] = useState(false);   
    console.log(selectionId.selectionId);
    
    const filterProducTiles = (productData) => {
        console.log(productData);
        
        let productTiles = [];
        if (productData) {
            productTiles = productData
            .filter((product) => {
                console.log(selectionId.selectionId);
                console.log(product.featured_section);
                return product.featured_section == selectionId.selectionId;
            })
            .map((product) => {
                return {
                    id: product.id,
                    featured_section: product.featured_section,
                    featured_product_image: product.featured_product_image,
                    featured_name: product.featured_name,
                    link_page: product.link_page,
                    orientation_number: product.orientation_number,
                };
            });
        }
        console.log(productTiles);
        setProductTiles(productTiles);
        return productTiles;
    }

    useEffect(() => {
        async function fetchProductData() {
            let productResData = await getData('/api/theme/landing_featured_product');
            console.log(productResData);
            let product_data = productResData['data']['products'];
            product_data = await filterProducTiles(product_data);
            if (product_data) {
                console.log(product_data);  
                
                // setProductTiles(product_data);
            } else {
                console.error("Error fetching featured product data:", productResData.message);
            }
            
            setLoading(false);
        }

        fetchProductData();
    }, [selectionId]);  
    
    const handleSaveTile = async (index) => {
        console.log(productTiles[index]);
        
        let productReqData = {
            id: productTiles[index].id ? productTiles[index].id : '',
            featured_section: productTiles[index].featured_section,
            featured_product_image: productTiles[index].featured_product_image,
            featured_name: productTiles[index].featured_name,
            link_page: productTiles[index].link_page,
            orientation_number: productTiles[index].orientation_number,
            status: productTiles[index].status?productTiles[index].status:'',
        };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        if (productTiles[index].featured_product_imagee && productTiles[index].featured_product_image instanceof Blob) {
            productReqData.featured_product_image = await convertToBase64(productTiles[index].featured_product_image);
        }
        // else if (isEditing && editingImageUrl) {
        //     iconReqData.icon_image = editingImageUrl;
        // }


        console.log(productReqData);

        try {
            let productResData = await postData('/api/theme/landing_featured_product', productReqData);
            if (productResData['data']['code'] === '200') {
                alert('Product saved successfully.');
                
            } else {
                alert('Error in saving product. Please try again.');
            }
        } catch (error) {
            console.error('Error in saving product:', error);
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const imageUrl = URL.createObjectURL(file);
            img.src = imageUrl;
            img.onload = () => {
                if (img.width <= 1440 && img.height <= 300) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        banner_image: imageUrl,
                        imageUrl: file
                    }));
                } else {
                    alert('Image must be 1440px wide and 300px tall or smaller.');
                }
            };
        }
    };

    const handleToggle = () => {
        setIsVisible((prev) => !prev);
    };

    const handleCollectionNameChange = (e, index) => {
        const updatedData = [...productTiles];
        updatedData[index].collectionName = e.target.value;  
        setProductTiles(updatedData);
    };

    const handleURLChange = (e, index) => {
        const newTiles = [...productTiles];
        newTiles[index].page_link = e.target.value;
        setProductTiles(newTiles);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(productTiles);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setProductTiles(items);
    };

    const handleEditTile = (e, index) => {
        e.stopPropagation();
        setEditingIndex(index);
    };

    const handleDeleteTile = (e, index) => {
        e.stopPropagation();
        const updatedTiles = productTiles.filter((_, i) => i !== index);
        setProductTiles(updatedTiles);
    };

    const handleAddTile = () => {
        const newTile = { id: '', featured_section: '', featured_product_image: '', featured_name: '', link_page: '', orientation_number: '' };        
        setProductTiles([...productTiles, newTile])
    };

    return (
        <Box>      
            <Box sx={{ display: 'flex', alignItems: 'center',marginBottom: 3 }}>
                <TextField label="Sub category title" disabled variant="outlined" placeholder="Shop top rated" InputLabelProps={{ shrink: true }} onChange={handleURLChange} sx={{ flexGrow: 1 }} />
                <IconButton aria-label="edit" sx={{ color: 'grey' }} onClick={() => console.log('Editing Section Title')}>
                    <EditOutlinedIcon />
                </IconButton>
            </Box>                    
            <Typography sx={{ my: 2 }}>Category cards</Typography>
            <Divider sx={{ marginBottom: 3 }} />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="productTiles">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {productTiles.length > 0 ? (
                                productTiles.map((tile, index) => (
                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <Accordion ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ backgroundColor: '#eceff1', marginBottom: 2, ...provided.draggableProps.style, borderRadius: 1 }}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                        <MenuIcon sx={{ cursor: 'pointer', marginRight: 0 }} />
                                                        <Box component="img" src={tile.featured_product_image} sx={{ width: 70, height: 40, objectFit: 'cover', borderRadius: 0, marginRight: 1 }} />
                                                        <Typography variant="h7">Fabric Collection card {index + 1}</Typography>
                                                    </Typography>
                                                    {/*<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                                                        <IconButton sx={{ mr: 1, color: 'grey', mb: 0, mt: 0}} onClick={(e) => {handleEditTile(e, index); handleToggle();}}>
                                                            {isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                        </IconButton>
                                                        <IconButton sx={{ mr: 2, color: 'grey' }} onClick={(e) => handleEditTile(e, index)}><EditOutlinedIcon /></IconButton>
                                                        <IconButton sx={{ color: 'red' }} onClick={(e) => handleDeleteTile(e, index)}><DeleteOutlinedIcon /></IconButton>
                                                    </div>*/}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                            <Box component="img" src={tile.featured_product_image} alt="Fabric Collection" sx={{ width: '300px', height: '150px', border: '1px solid grey' }} />
                                                            <IconButton component="label" sx={{ color: 'black' }}>
                                                                <FileUploadOutlinedIcon />
                                                                <input hidden accept="image/*" type="file" onChange={(e) => handleImageUpload(e, index)} />
                                                            </IconButton>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <TextField label="Collection Name" disabled variant="outlined" InputLabelProps={{ shrink: true }} value={tile.featured_name} onChange={(e) => handleCollectionNameChange(e, index)} sx={{ flexGrow: 1, mt: 1 ,'& .MuiInputBase-root': { borderBottom: '1px solid grey' }, '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }} />
                                                            <IconButton aria-label="edit" src={{ color: 'grey' }} onClick={() => (`Collection Name ${index + 1}`)}>
                                                                <EditOutlinedIcon />
                                                            </IconButton>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <TextField select label="CTA - Link collection page" value={tile.link_page}  sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} >
                                                                <MenuItem value="collection1">Collection 1</MenuItem>
                                                                <MenuItem value="collection2">Collection 2</MenuItem>
                                                                <MenuItem value="collection3">Collection 3</MenuItem>
                                                            </TextField>    
                                                        </Box>  
                                                        <Divider sx={{ my: 2 }}>or</Divider>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <TextField label="URL link to collection page" variant="outlined" placeholder="URL" InputLabelProps={{ shrink: true }} onChange={handleURLChange} sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} />
                                                        </Box>
                                                    </Box>                                                    
                                                    <Button variant="contained" onClick={() => handleSaveTile(index)} sx={{ ...buttonStyle, mt: 2 }}>
                                                        Save
                                                    </Button> 
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                    </Draggable>
                                ))
                                ) : (
                                    <Typography>No category tiles available</Typography>
                            )}                    
                            {provided.placeholder}
                            {/*<IconButton variant="contained" sx={{ ...buttonStyle, mt: 1 }} onClick={handleAddTile}>
                                <AddIcon />
                            </IconButton>*/}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>       
        </Box>
    );
}
