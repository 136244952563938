import React from 'react';
import { Typography, Box, TextField, IconButton } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };

export default function GeneralSettings() {
    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', marginLeft: 11, marginTop: 0 }}>            
            <Box sx={{ padding: 2, boxShadow: 3 }}>
                <Typography variant="h6">
                    General Settings
                </Typography>                    
            </Box>    
            <Box sx={{ boxShadow: 3, backgroundColor: '#ECEFF1', p: 2 }}>                        
                <Typography variant="h7">
                    Net Quantity Limit
                </Typography>
                <TextField fullWidth label="Net Quantity" type="number" variant="outlined" margin="normal" sx={{ backgroundColor: '#fff' }} />
                <IconButton sx={{ ...buttonStyle, marginTop: 2 }} variant="contained">
                    <UpdateIcon />
                </IconButton>                        
            </Box>           
        </Box>
    );
};
