import React, { useState } from 'react';
import { ChevronLeftOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Divider } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'; // Import default styles
import IconButton from '@mui/material/IconButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LockIcon from '@mui/icons-material/Lock';
import CampaignIcon from '@mui/icons-material/Campaign';
import UpdateIcon from '@mui/icons-material/Update';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import TagIcon from '@mui/icons-material/Tag';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import TaskIcon from '@mui/icons-material/Task';
import MessageIcon from '@mui/icons-material/Message';
import DescriptionIcon from '@mui/icons-material/Description';
import PaletteIcon from '@mui/icons-material/Palette';
import GridViewIcon from '@mui/icons-material/GridView';
import CollectionsIcon from '@mui/icons-material/Collections'

function Sidebar({ open, handleDrawerToggle }) {
    const [openProduct, setOpenProduct] = useState(false);
    const [openBuyer, setOpenBuyer] = useState(false);
    const [openOrders, setOpenOrders] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [openCustomerSupport, setOpenCustomerSupport] = useState(false);
    const [openTheme, setOpenTheme] = useState(false);

    const handleClickProduct = () => {
        setOpenProduct(!openProduct);
    };

    const handleClickBuyer = () => {
        setOpenBuyer(!openBuyer);
    };

    const handleClickOrders = () => {
        setOpenOrders(!openOrders);
    };

    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };

    const handleClickCustomerSupport = () => {
        setOpenCustomerSupport(!openCustomerSupport);
    }

    const handleClickTheme = () => {
        setOpenTheme(!openTheme);
    };

    return (
        <Drawer variant="persistent" open={open} sx={{ '& .MuiDrawer-paper': { backgroundColor: 'rgba(0, 0, 0, 0.88)', width: 245 } }}>
            <IconButton onClick={handleDrawerToggle}>
                <ChevronLeftOutlined />
            </IconButton>
            <PerfectScrollbar>
            <List sx={{ marginTop: 4, py: 2 }}>
                <ListItem button component={Link} to="./dashboard/Dashboard">
                     <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard"
                                  primaryTypographyProps={{ 
                                    fontFamily: 'Roboto Condensed, sans-serif', 
                                    textTransform: 'uppercase', 
                                    fontSize: '0.85rem',
                                    color: '#ECEFF1',
                                    fontWeight: 'bold', 
                                     
                                }} />
                </ListItem>
                <Divider sx={{ backgroundColor: '#263238' }}/>
                <ListItem button onClick={handleClickProduct}n sx={{ py: 2 }}>
                     <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                        <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Product"
                                  primaryTypographyProps={{ 
                                    fontFamily: 'Roboto Condensed, sans-serif', 
                                    textTransform: 'uppercase', 
                                    fontSize: '0.85rem',
                                    color: '#ECEFF1',
                                    fontWeight: 'bold', 
                                }} />
                    {openProduct ? <ExpandLess sx={{ color: '#ECEFF1' }} /> : <ExpandMore sx={{ color: '#ECEFF1' }} />}
                </ListItem>
                <Collapse in={openProduct} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="./products/catalogues" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <InventoryIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Catalogues"
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                           
                                            color: '#ECEFF1'
                                        }} />
                        </ListItem>
                        <ListItem button component={Link} to="./products/collection" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <CollectionsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Collection"
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                           
                                            color: '#ECEFF1'
                                        }} />
                        </ListItem>
                        <ListItem button component={Link} to="./products/product-list" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <DescriptionIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Product List" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./products/tagCategory" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <CategoryIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Category" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./products/tags" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <TagIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Tags" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./products/enquiries" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <MessageIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Enquiries" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        {/* <ListItem button component={Link} to="./products/productLastUpdated" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <PriceChangeIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Last Updated" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                             
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem> */}
                    </List>
                </Collapse>
                <Divider sx={{ backgroundColor: '#263238' }}/>
                <ListItem button onClick={handleClickBuyer} sx={{ py: 2 }}>
                     <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Buyers"
                                  primaryTypographyProps={{ 
                                    fontFamily: 'Roboto Condensed, sans-serif', 
                                    textTransform: 'uppercase', 
                                    fontSize: '0.85rem', 
                                    color: '#ECEFF1',
                                    fontWeight: 'bold', 
                                }} />
                    {openBuyer ? <ExpandLess sx={{ color: '#ECEFF1' }} /> : <ExpandMore sx={{ color: '#ECEFF1' }} />}
                </ListItem>
                <Collapse in={openBuyer} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="./buyers/list" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <ListAltIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Buyers List" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                             
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./buyers/invite" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <PersonAddIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Invite Buyers" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                              
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ backgroundColor: '#263238' }}/>
                <ListItem button onClick={handleClickOrders} sx={{ py: 2 }}>
                     <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                        <TaskIcon />
                    </ListItemIcon>
                    <ListItemText primary="Orders"
                                  primaryTypographyProps={{ 
                                    fontFamily: 'Roboto Condensed, sans-serif', 
                                    textTransform: 'uppercase', 
                                    fontSize: '0.85rem',
                                    color: '#ECEFF1',
                                    fontWeight: 'bold', 
                                }} />
                    {openOrders ? <ExpandLess sx={{ color: '#ECEFF1' }} /> : <ExpandMore sx={{ color: '#ECEFF1' }} />}
                </ListItem>
                <Collapse in={openOrders} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <ListItem button  component={Link} to="./orders/list" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <ListAltIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Order List" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem', 
                                            color: '#ECEFF1'
                                        }} />
                        </ListItem> */}
                        <ListItem button component={Link} to="./orders/wishlist" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <AddShoppingCartIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Wishlists" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }} />
                        </ListItem>
                        {/* <ListItem button component={Link} to="./orders/in-transit" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <LocalShippingIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="In Transit" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                         
                                            color: '#ECEFF1'
                                        }} />
                        </ListItem> */}
                    </List>
                </Collapse>
                <Divider sx={{ backgroundColor: '#263238' }}/>
                <ListItem button onClick={handleClickTheme} sx={{ py: 2 }}>
                     <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                        <PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary="App Settings"
                                  primaryTypographyProps={{ 
                                    fontFamily: 'Roboto Condensed, sans-serif', 
                                    textTransform: 'uppercase', 
                                    fontSize: '0.85rem', 
                                    color: '#ECEFF1',
                                    fontWeight: 'bold'
                                }} />
                    {openTheme ? <ExpandLess sx={{ color: '#ECEFF1' }} /> : <ExpandMore sx={{ color: '#ECEFF1' }} />}
                </ListItem>
                <Collapse in={openTheme} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="./theme/landing-page" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <GridViewIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Landing Page" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./theme/landing-header" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <GridViewIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Header" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./theme/landing-footer" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <GridViewIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Footer" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                    </List>
                </Collapse>    
                <Divider sx={{ backgroundColor: '#263238' }}/>    
                {/* <ListItem button onClick={handleClickSettings} sx={{ py: 2 }}>
                     <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings"
                                  primaryTypographyProps={{ 
                                    fontFamily: 'Roboto Condensed, sans-serif', 
                                    textTransform: 'uppercase', 
                                    fontSize: '0.85rem',
                                    color: '#ECEFF1',
                                    fontWeight: 'bold',  
                                }} />
                    {openSettings ? <ExpandLess sx={{ color: '#ECEFF1' }} /> : <ExpandMore sx={{ color: '#ECEFF1' }} />}
                </ListItem> */}
                {/* <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="./settings/generalSettings" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="General Settings" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                 
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./settings/updateProfile" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Update Profile"
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                             
                                            color: '#ECEFF1'
                                        }} />
                        </ListItem>
                        <ListItem button component={Link} to="./settings/changePhoto" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <PhotoCameraIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Change Photo"
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                             
                                            color: '#ECEFF1'
                                        }} />
                        </ListItem>
                        <ListItem button component={Link} to="./settings/changePassword" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <LockIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Change Password" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./settings/changeCenterAd" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <CampaignIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Center Advertisement" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                        <ListItem button component={Link} to="./settings/updateLiveData" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <UpdateIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Update Live Data" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                    </List>
                </Collapse> */}
                <Divider sx={{ backgroundColor: '#263238' }}/>  
                {/* <ListItem button onClick={handleClickCustomerSupport} sx={{ py: 2 }}>
                     <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                        <SupportAgentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Support"
                                  primaryTypographyProps={{ 
                                    fontFamily: 'Roboto Condensed, sans-serif', 
                                    textTransform: 'uppercase', 
                                    fontSize: '0.85rem', 
                                    color: '#ECEFF1',
                                    fontWeight: 'bold'
                                }} />
                    {openCustomerSupport ? <ExpandLess sx={{ color: '#ECEFF1' }} /> : <ExpandMore sx={{ color: '#ECEFF1' }} />}
                </ListItem> */}
                {/* <Collapse in={openCustomerSupport} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button component={Link} to="./customerSupport/queries" sx={{ pl: 4 }}>
                             <ListItemIcon  sx={{ minWidth: 35, color: '#ECEFF1' }} >
                                <QuestionMarkIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Queries" 
                                          primaryTypographyProps={{ 
                                            fontFamily: 'Roboto Condensed, sans-serif', 
                                            textTransform: 'uppercase', 
                                            fontSize: '0.75rem',                                            
                                            color: '#ECEFF1'
                                        }}/>
                        </ListItem>
                    </List>
                </Collapse> */}
                <Divider sx={{ backgroundColor: '#263238' }}/>        
            </List>
            </PerfectScrollbar>
        </Drawer>
    );
}

export default Sidebar;