import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Drawer } from '@mui/material';
import Icon from '@mui/material/Icon';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';

function CardComponent({ heading, percentageV, value, icon }) {
    const data = [
        { name: 'group A', value: 4 },
        { name: 'group B', value: 3 },
        { name: 'group C', value: 5 },
    ];
    return (
        <Card sx={{ maxWidth: 345, backgroundColor: '#ECEFF1', borderRadius: '8px', boxShadow: 4, padding: '12px' }}>
            <CardHeader
                avatar={
                    icon === 'orders' ? <ShoppingCartIcon sx={{ color: '', fontSize: 25 }} /> :
                    icon === 'customers' ? <PeopleIcon sx={{ color: '', fontSize: 25 }} /> :
                    <Icon sx={{ color: red[500] }}>folder</Icon>
                }
                title={<Typography variant="h8" sx={{ fontSize: '1.0rem' }}>{heading}</Typography>}
                titleTypographyProps={{ variant: 'h8' }} // Ensure title uses h6 styling
            />

            <CardContent>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body2" color="text.secondary">
                        {percentageV}% increase
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                        {value}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

export default CardComponent;
