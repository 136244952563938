import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Container, Box, Divider, TextField, Button, IconButton } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

export default function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log("Current Password:", currentPassword);
        console.log("New Password:", newPassword);
        console.log("Confirm Password:", confirmPassword);
    };

    const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', marginLeft: 11, marginTop: 0 }}>            
            <Box sx={{  padding: 2, boxShadow: 3 }}>
                <Typography variant="h6">
                    Change Password
                </Typography>
            </Box>    
            <Box sx={{ boxShadow: 3, backgroundColor: '#ECEFF1', p: 2 }}>                        
                <Typography variant="h7">
                    Update Your Password
                </Typography>               
                <TextField fullWidth label="Current Password" type="password" variant="outlined" margin="normal" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} sx={{ backgroundColor: '#fff' }} />
                <TextField fullWidth label="New Password" type="password" variant="outlined" margin="normal" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} sx={{ backgroundColor: '#fff' }} />
                <TextField fullWidth label="Confirm New Password" type="password" variant="outlined" margin="normal" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} sx={{ backgroundColor: '#fff' }} />
                <IconButton sx={{ ...buttonStyle, marginTop: 2 }} variant="contained">
                    <UpdateIcon />
                </IconButton>              
            </Box>
        </Box>
    );
};
