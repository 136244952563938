import React, { useState } from 'react';
import { Typography, Box, Button, Avatar } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export default function ChangePhoto() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState('/path/to/current/photo.jpg'); 

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleUpload = () => {
        // Handle file upload logic here
        console.log("Upload file:", selectedFile);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 11, marginTop: 0 }}>            
            <Box sx={{ padding: 2, boxShadow: 3 }}>
                <Typography variant="h6">
                    Change Photo
                </Typography>
            </Box>                                  
            <Box sx={{ boxShadow: 3, backgroundColor: '#ECEFF1', p: 2 }}>                        
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ width: 120, height: 120, marginBottom: 2, bgcolor: 'transparent', border: '2px dashed black' }}>
                        <CameraAltIcon sx={{ fontSize: 60, color: 'black' }} /> 
                    </Avatar>
                    <input accept="image/*" style={{ display: 'none' }} id="upload-button" type="file" onChange={handleFileChange} />
                        <label htmlFor="upload-button">
                        <Button variant="contained" component="span" sx={{ background: '#f8f9fa', borderRadius: 2, border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } }}>
                            Choose Photo
                        </Button>
                        </label>
                        <Button variant="contained" sx={{ background: '#f8f9fa', borderRadius: 2, border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' }, mt: 2 }} onClick={handleUpload} disabled={!selectedFile}>
                            Upload Photo
                        </Button>
                </Box>
            </Box>
        </Box>
    );
};
