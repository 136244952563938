import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, IconButton, TextField, MenuItem, Divider } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import FeaturedProduct from "./FeaturedProduct";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, borderRadius: 0, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };

export default function LandingPageFeatured() {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [sectionData, setSectionData] = useState([]);
    const [formValues, setFormValues] = useState({ altText: '', title: '', heading: '', ctaText: '', ctaLinkType: '', url: '', featured_image: '' });    
    const [selectedSection, setSelectedSection] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [editingImageUrl, setEditingImageUrl] = useState('');
    const [isEditing, setIsEditing] = useState(false); 
    const [expanded, setExpanded] = useState(false);  
    const [selectedSectionId, setSelectedSectionId] = useState(null);

    const handleFeaturedSubmit = async () => {
        let sectionReqData = {
            id: formValues.id ? formValues.id : '',
            image_alt_text: formValues.image_alt_text,
            title: formValues.title,
            heading: formValues.heading,
            cta_text: formValues.cta_text,
            link_page: formValues.link_page,
            featured_image: formValues.featured_image,
        };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        };

        if (formValues.featured_image && formValues.featured_image instanceof Blob) {
            sectionReqData.featured_image = await convertToBase64(formValues.featured_image);
        } else if (isEditing && editingImageUrl) {
            sectionReqData.featured_image = editingImageUrl;
        }


        console.log(sectionReqData);

        try {
            let bannerResData = await postData('/api/theme/landing_featured_section', sectionReqData);
            if (bannerResData['data']['code'] === '200') {
                alert('Section saved successfully.');
                if (isEditing) {
                    setSectionData(sectionData.map(section => section.id === selectedSection.id ? formValues : section));
                } else {
                    const newSection = { ...formValues, id: sectionData.length + 1 };
                    setSectionData([...sectionData, newSection]);
                }
                setIsFormVisible(false);
            } else {
                alert('Error saving section. Please try again.');
            }
        } catch (error) {
            console.error('Error saving section:', error);
        }
    };

    useEffect(() => {
        async function fetchSectionData() {
            let sectionResData = await getData('/api/theme/landing_featured_section?is_admin=true');
            console.log(sectionResData);
            let section_data = sectionResData['data']['sections'];
            if (section_data) {
                setSectionData(section_data);
            } else {
                console.error("Error fetching featured section data:", sectionResData.message);
            }          

            setLoading(false);
        }

        fetchSectionData();
    }, []);

    const handleVisibilityToggle = async (id, newStatus) => {
        // Prepare request payload
        const visibilityReqData = {
            id, // Pass the banner ID
            status: newStatus ? 'enabled' : 'disabled', // Update the status
        };
    
        try {
            // Post request to update the banner status
            let visibilityResData = await postData('/api/theme/landing_featured_section?is_admin = true', visibilityReqData);
    
            // Handle response
            if (visibilityResData['data']['code'] === '200') {
                alert('Banner visibility updated successfully.');
                setSectionData((prevBanners) =>
                    prevBanners.map((banner) =>
                        banner.id === id ? { ...banner, status: visibilityReqData.status } : banner
                    )
                );
            } else {
                alert('Error updating banner visibility. Please try again.');
            }
        } catch (error) {
            console.error('Error updating banner visibility:', error);
        }
    };

    const handleAddNewSection = (image) => {
        setFormValues({ altText: '', title: '', heading: '', ctaText: '', ctaLinkType: '', url: '', featured_image: '' });
        setSelectedSection(image);
        setIsFormVisible(true); 
        setIsEditing(true);
        setExpanded(true);  
    };

    const handleEditImage = (image) => {
        setSelectedSectionId(image.id);
        setFormValues(image);
        setSelectedSection(image);
        setIsFormVisible(true);
        setIsEditing(true);
        setExpanded(true);
        console.log(image);
    };

    const handleDeleteImage = (id) => {
        setImages(images.filter((image) => image.id !== id));
    };

    const handleFormChange = (field, value) => {
        setFormValues((prev) => ({ ...prev, [field]: value }));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const imageUrl = URL.createObjectURL(file);
            img.src = imageUrl;
            img.onload = () => {
                if (img.width <= 1440 && img.height <= 300) {
                    setFormValues((prevState) => ({
                        ...prevState,
                        featured_image: file, // Store the file object
                        imageUrl: imageUrl  // Store the object URL for preview
                    }));
                } else {
                    alert('Image must be 1440px wide and 300px tall or smaller.');
                }
                URL.revokeObjectURL(imageUrl); // Free up memory
            };
            img.onerror = () => {
                alert('Failed to load image. Please try again.');
                URL.revokeObjectURL(imageUrl); // Free up memory
            };
        }
    };

    return (
        <Box>
            <Accordion sx={sectionStyle}>
                
                {/* Featured Section */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>    
                    <Typography variant="h7">Featured</Typography>
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ background: '#eceff1', padding: 4, borderRadius: 1 }}>                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-around', marginBottom: 1 }}>
                            {sectionData.length > 0 ? (
                                sectionData.map((image, index) => (  
                                    <Box key={image.id} sx={{ textAlign: 'center' }}>
                                        <img src={image.featured_section_image} alt={`Featured ${image.id}`} style={{ width: '140px', height: '60px', marginBottom: '10px', marginTop: '10px', filter: image.status === 'enabled' ? 'none' : 'blur(3px)', opacity: image.status === 'enabled' ? 1 : 0.6, transition: 'filter 0.3s ease-in-out' }} />
                                        <Typography variant="body2">{image.section_title}</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <IconButton sx={{ mr: 2, color: 'grey' }} onClick={() => handleVisibilityToggle(image.id, image.status !== 'enabled')}>
                                                {image.status === 'enabled' ? (
                                                    <VisibilityOutlinedIcon />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon />
                                                )}
                                            </IconButton>
                                            <IconButton sx={{ mr: 0, color: 'grey' }} onClick={() => handleEditImage(image)}><EditOutlinedIcon /></IconButton>
                                            {/*<IconButton sx={{ color: 'red' }} onClick={() => handleDeleteImage(image.id)}><DeleteOutlinedIcon /></IconButton>*/}
                                        </Box>                    
                                    </Box>      
                                ))
                            ) : (
                                <Typography>No cards added yet.</Typography>
                            )}                                 
                        </Box>
                        {/*<IconButton sx={{ ...buttonStyle, mt: 2 }} onClick={handleAddNewSection}><AddIcon /></IconButton>*/}
                    </Box>                                                                
                    
                    {/* Edit Featured section */}                    
                    <Accordion sx={{ backgroundColor: '#eceff1', mt: 2, mb: 2, borderRadius: 1 }} expanded={expanded}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
                            <Typography variant="h7">Edit Featured Card</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {isFormVisible && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>                               
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField label="Section Title" disabled variant="outlined" InputLabelProps={{ shrink: true }} value={formValues.section_title} onChange={(e) => handleFormChange('section_title', e.target.value)} helperText="Max 6 characters" inputProps={{ maxLength: 6 }} sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} />
                                    <IconButton aria-label="edit" sx={{ mb: 3, color: 'grey' }} onClick={() => console.log('Editing Section Title')}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                </Box>                                                              
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>                    
                                    {formValues.featured_section_image || isEditing ? (
                                        <figure>
                                        <img alt="Preview" src={formValues.featured_section_image || (isEditing ? editingImageUrl : null)} style={{ width: '350px', height: '150px', border: '1px solid #ccc', marginBottom: 2 }} />
                                        <figcaption>W 1440px / H 300px</figcaption>
                                        </figure>
                                    ) : (
                                        <Box sx={{ width: '400px', height: '150px', border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography variant="body2" color="textSecondary">Image Placeholder (1440x600)</Typography>
                                        </Box>
                                    )}                    
                                    <IconButton component="label" sx={{ color: 'black' }}>
                                        <FileUploadOutlinedIcon />
                                        <input hidden accept="image/*" type="file" onChange={(e) => handleImageUpload(e)} />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField label="Title" disabled variant="outlined" placeholder="Name of the tile" InputLabelProps={{ shrink: true }} value={formValues.title} onChange={(e) => handleFormChange('title', e.target.value)} helperText="Max 15 characters including space" inputProps={{ maxLength: 15 }} sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} />
                                    <IconButton aria-label="edit" sx={{ mb: 3, color: 'grey' }} onClick={() => console.log('Editing Title')}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField label="Heading" disabled variant="outlined" placeholder="Special feature of the tile and theme" InputLabelProps={{ shrink: true }} value={formValues.heading} onChange={(e) => handleFormChange('heading', e.target.value)} helperText="Max 40 characters including space" inputProps={{ maxLength: 40 }} sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} />
                                    <IconButton aria-label="edit" sx={{ mb: 3, color: 'grey' }} onClick={() => console.log('Editing Heading')}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField label="CTA Text" disabled variant="outlined" placeholder="Type your text here" InputLabelProps={{ shrink: true }} value={formValues.cta_text} onChange={(e) => handleFormChange('cta_text', e.target.value)} helperText="Max 10 characters including space" inputProps={{ maxLength: 10 }} sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} />                                    
                                    <IconButton aria-label="edit" sx={{ mb: 3, color: 'grey' }} onClick={() => console.log('Editing Heading')}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField select label="CTA - Link collection page" value={formValues.link_page} onChange={(e) => handleFormChange('link_page', e.target.value)} sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} >
                                        <MenuItem value="collection1">Collection 1</MenuItem>
                                        <MenuItem value="collection2">Collection 2</MenuItem>
                                        <MenuItem value="collection3">Collection 3</MenuItem>
                                    </TextField>    
                                </Box>  
                                <Divider sx={{ my: 2 }}>or</Divider>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <TextField label="URL link to collection page" variant="outlined" placeholder="URL" InputLabelProps={{ shrink: true }} value={formValues.link_page} onChange={(e) => handleFormChange('link_page', e.target.value)} sx={{ flexGrow: 1, '& .MuiInputBase-root': { backgroundColor: 'white' } }} />
                                </Box>
                                <Box>
                                <Button variant="contained" onClick={() => handleFeaturedSubmit()} sx={{ ...buttonStyle, mt: 2 }}>
                                    Save
                                </Button>
                                </Box>    
                            </Box>
                        )}                                                  
                        </AccordionDetails>
                    </Accordion>              
                    <Divider sx={{ marginBottom: 3, marginTop: 2 }} />
                    
                    {/* Featured Product */}
                    {selectedSectionId? <FeaturedProduct selectionId={selectedSectionId} /> : null}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}
