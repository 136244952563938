import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, TextField, IconButton } from '@mui/material';
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

const buttonStyle = { marginTop: 2, background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };

export default function LandingPageEnquiry() {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState([]);

    const handleFormSubmit = async () => {
        console.log(formData);
        
        let formReqData = {
            'id': formData.id ? formData.id : '',
            'form_heading': formData.form_heading ? formData.form_heading : '',
            'subheading': formData.subheading ? formData.subheading : '',
            'cta_text': formData.cta_text ? formData.cta_text : '',
        };
        console.log(formReqData);
        let formResData = await postData('/api/theme/landing_form', formReqData);
        if (formResData['data']['code'] !== 200) {
            alert('Form data saved successfully');
            setLoading(false);
            return true;
        }
        else {
            alert('Error saving form data');
            setLoading(false);
        }
        return false;
    };

    useEffect(() => {
        async function fetchFormsData() {
            let resData = await getData('/api/theme/landing_form');
            console.log(resData);
            let forms_data = resData['data']['forms']
            if (forms_data) {
                console.log(forms_data);
                if (forms_data) {
                    setFormData(forms_data);
                }
            } else {
                console.error("Error fetching form data:", resData.message);
            }
            setLoading(false);
        }

        fetchFormsData();
    }, []);

    return (
        <Accordion sx={sectionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>
                <Typography variant="h7">Form</Typography>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <form>
                        <TextField multiline rows={3} type="text" label="Form heading" helperText="Max 50 characters including space" InputLabelProps={{ shrink: true }} inputProps={{ maxLength: 50 }} fullWidth variant="outlined" margin="normal" placeholder="Write title text here" value={formData.form_heading} onChange={(e) => setFormData({ ...formData, form_heading: e.target.value })} />                        
                        <TextField type="text" label="Sub heading" helperText="Max 30 characters including space" InputLabelProps={{ shrink: true }} inputProps={{ maxLength: 30 }} fullWidth variant="outlined" margin="normal" placeholder="Write text here" value={formData.subheading} onChange={(e) => setFormData({ ...formData, subheading: e.target.value })} />
                        <TextField type="text" label="CTA text" helperText="Max 10 characters including space" InputLabelProps={{ shrink: true }} inputProps={{ maxLength: 10 }} fullWidth variant="outlined" margin="normal" placeholder="Write text here" value={formData.cta_text} onChange={(e) => setFormData({ ...formData, cta_text: e.target.value })} />
                    </form>
                    <Button sx={buttonStyle} onClick={handleFormSubmit}>Save</Button>
                </Box>
            </AccordionDetails>
        </Accordion>

    );
}


