import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, IconButton, Divider, TextField, MenuItem, Autocomplete } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getData, postData } from "../../../ServerRequest";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridViewIcon from '@mui/icons-material/GridView';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const buttonStyle = { background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } };
const sectionStyle = { backgroundColor: 'rgba(255, 255, 255, 0.6)', marginBottom: 2, boxShadow: '0px 4px 8px rgba(0, 0, 1, 0.1)' };
const collectionPages = [{ id: 1, title: 'Collection Page 1', url: '/collection-page-1' }, { id: 2, title: 'Collection Page 2', url: '/collection-page-2' }, { id: 3, title: 'Collection Page 3', url: '/collection-page-3' }];

export default function LandingPageFabric() {
    const [loading, setLoading] = useState(false);
    const [fabrics, setFabrics] = useState([]);
    const [fabricTiles, setFabricTiles] = useState([]);
    const [previewImage, setPreviewImage] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [collectionOptions, setCollectionOptions] = useState([]);

    const handleFabricsSubmit = async (index) => {
        let fabric = fabrics[index];
        let fabricReqData  = {
            'id': fabric.id ? fabric.id : '',
            'title': fabric.title ? fabric.title : '',
            'subheading': fabric.subheading ? fabric.subheading : '',
            'page_link': fabric.page_link ? fabric.page_link : '',
            'orientation_number': fabric.orientation_number ? fabric.orientation_number : '',
            'fabric_image': fabric.fabric_image ? fabric.fabric_image : ''
        };        

        let fabricsResData = await postData('/api/theme/landing_fabric', fabricReqData);
        if (fabricsResData['data']['code'] !== 200) {
            alert('Fabrics data saved successfully');
            setLoading(false);
            return true;
        }
        else {
            alert('Error saving fabrics data');
            setLoading(false);
        }
        return false;
    };

    useEffect(() => {
        async function fetchFabricData() {
            let resData = await getData('/api/theme/landing_fabric?is_admin=true');
            console.log(resData);
            let fabrics_data = resData['data']['fabrics']
            if (fabrics_data) {
                console.log(fabrics_data);
                if (fabrics_data) {
                    setFabrics(fabrics_data);
                }
            } else {
                console.error("Error fetching fabrics data:", resData.message);
            }
            setLoading(false);
        }

        fetchFabricData();
    }, []);

    const handleImageUpload = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
                fabrics[index].fabric_image = reader.result;
               
            };
            reader.readAsDataURL(file);
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(fabricTiles, fabrics);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFabricTiles(items);
        setFabrics(items);
    };

    const handleEditTile = (e, index) => {
        e.stopPropagation();
        setEditingIndex(index);
    };

    const handleDeleteTile = (e, index) => {
        e.stopPropagation();
        const updatedTiles = fabricTiles.filter((_, i) => i !== index);
        setFabricTiles(updatedTiles);
    };

    const handleSubheadingChange = (e, index) => {
        let newFabric = [...fabrics];
        newFabric[index].subheading = e.target.value;
        setFabricTiles(newFabric);
    };

    const handleCollectionPageChange = (e, index) => {
        let newFabric = [...fabrics];
        newFabric[index].page_link = e.target.value;
        setFabrics(newFabric);
    };


    const handleTitleChange = (e, index) => {
        let newFabric = [...fabrics];
        newFabric[index].title = e.target.value;
        setFabrics(newFabric);
    };

    const handleURLChange = (e, index) => {
        let newFabric = [...fabrics];
        newFabric[index].page_link = e.target.value;
        setFabrics(newFabric);
    };

    const handleAddTile = () => {
        setFabrics([...fabrics, { title: '', subheading: '', page_link: '', fabric_image: '' }]);
    };

    return (
        <Accordion sx={sectionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>            
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton color="" component="icon">
                    <GridViewIcon />
                </IconButton>
                <Typography variant="h7">Special Fabrics</Typography>
            </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{ marginBottom: 2 }}>Section Tiles</Typography>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="Fabrics">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {fabrics.length > 0 ? (
                                    fabrics.map((tile, index) => (
                                        <Draggable key={tile} draggableId={tile} index={index}>
                                            {(provided, snapshot) => (
                                                <Accordion ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={{ backgroundColor: '#eceff1', marginBottom: 2, ...provided.draggableProps.style, borderRadius: 1 }}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                            <MenuIcon sx={{ cursor: 'pointer', marginRight: 2 }} />
                                                            <Box component="img" src={tile.fabric_image} sx={{ width: 60, height: 40, objectFit: 'cover', borderRadius: 0, marginRight: 2 }} />
                                                            <Typography>Special {tile.title}</Typography>
                                                        </Typography>
                                                        {/*<div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
                                                            <IconButton sx={{ mr: 2, color: 'grey' }} onClick={(e) => handleEditTile(e, index)}><EditOutlinedIcon /></IconButton>
                                                            <IconButton sx={{ color: 'red' }} onClick={(e) => handleDeleteTile(e, index)}><DeleteOutlinedIcon /></IconButton>
                                                        </div>*/}
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                                                                <Box component="img" src={tile.fabric_image || previewImage} sx={{ width: 140, height: 70, objectFit: 'cover', borderRadius: 0, marginRight: 2, marginLeft: 2 }} />
                                                                <div>
                                                                    <IconButton sx={{ color: 'black' }} component="label">
                                                                        <FileUploadOutlinedIcon />
                                                                        <input type="file" hidden accept="image/png" onChange={(e) => handleImageUpload(e, index)} />
                                                                    </IconButton>                                
                                                                </div>
                                                            </Box>                                                
                                                        </div>
                                                        <Typography variant="caption" sx={{ marginLeft: 2 }}>W 1440px / H 600px</Typography>
                                                        <TextField fullWidth label="Title" placeholder="Write title text here" sx={{ '& .MuiInputBase-root': { backgroundColor: 'white' } }} helperText="Max 10 characters including space" variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} value={tile.title} onChange={(e) => handleTitleChange(e, index)} />
                                                        <TextField fullWidth label="Subheading" placeholder="Write text here" sx={{ '& .MuiInputBase-root': { backgroundColor: 'white' } }} helperText="Max 40 characters including space" variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} value={tile.subheading} onChange={(e) => handleSubheadingChange(e, index)} />
                                                        <Autocomplete
                                                            options={[{ label: "None", value: "none" }, { label: "option", value: "option" }, ...collectionOptions]}
                                                            getOptionLabel={(option) => option.label}
                                                            value={collectionOptions.find(option => option.value === tile.page_link) || null}
                                                            onChange={(event, newValue) => {
                                                                const updatedFabricTiles = [...fabricTiles];
                                                                updatedFabricTiles[index].page_link = newValue?.value || '';
                                                                updatedFabricTiles[index].isEditable = newValue?.value === 'none';
                                                                setFabricTiles(updatedFabricTiles);

                                                                // If "None" is selected, clear the linked_url and enable the text field
                                                                if (newValue?.value === 'none') {
                                                                    updatedFabricTiles[index].linked_url = ''; // Clear the URL when "None" is selected
                                                                }
                                                                setFabricTiles(updatedFabricTiles);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Link collection page"
                                                                    fullWidth
                                                                    sx={{
                                                                        
                                                                        marginBottom: 2, mr: 11,
                                                                        '& .MuiInputBase-root': { backgroundColor: 'white' },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <Divider sx={{ marginY: 2 }}>
                                                            <Typography variant="caption">or</Typography>
                                                        </Divider>
                                                        <TextField fullWidth label="URL link to the collection page" sx={{ '& .MuiInputBase-root': { backgroundColor: 'white' } }} placeholder="URL" variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} value={tile.page_link} onChange={(e) => handleURLChange(e, index)} disabled={!tile.isEditable} />                                                        
                                                        <Button onClick={() => handleFabricsSubmit(index)} sx={{ ...buttonStyle, mt: 2 }}>
                                                            Save
                                                        </Button> 
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <Typography>No fabrics available</Typography>
                                )}
                                {provided.placeholder}
                                {/*<IconButton variant="contained" sx={{ ...buttonStyle, mt: 1 }} onClick={handleAddTile}>
                                    <AddIcon />
                                </IconButton>*/}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </AccordionDetails>
        </Accordion>

    );
}


