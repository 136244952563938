import { React, useState } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Card, CardContent, Container, Typography, Button, TextField, Paper, Box, Dialog, DialogActions, DialogContent, TableCell, DialogTitle, IconButton } from '@mui/material';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { DataGrid } from '@mui/x-data-grid';
import CardComponent from '../../components/card/CardComponent';
import { Add } from '@mui/icons-material'; 
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export default function InviteBuyer() {
    const [emails, setEmails] = useState('');
    const [modalOpen, setModalOpen] = useState(false); 

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSendInvite = () => {
        console.log("Invites sent to: ", emails);
        alert(`Invites sent to: ${emails}`);
    };

    const columns = [
        {
            name: "name",
            label: "Buyer Name",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "phone",
            label: "Phone",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => {
                    let color;
                    switch (value) {
                        case "Invited":
                            color = "blue";
                            break;
                        case "Pending":
                            color = "orange";
                            break;
                        default:
                            color = "gray";
                    }
                    return <span style={{ color }}>{value}</span>;
                }
            }
        },
    ];

    const data = [
        { name: "", email: "", phone: "", status: "" },
        
    ];

    const options = {
        filterType: 'checkbox',
        customToolbar: () => (
            <IconButton onClick={handleOpenModal} sx={{ background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' }, mb:0 }}>
                <PersonAddIcon />    
            </IconButton>
        ),
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 8 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"Invite Buyers"}
                        data={data}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)', 
                        }}
                    />
                </Box>
            </Grid>

            {/* Send Invite Modal */}
            <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>            
                <DialogContent>
                    <Typography variant="h6" gutterBottom sx={{ marginBottom: 2 }}>
                        Buyer Email
                    </Typography>
                    <TextField
                        label="Enter buyer emails"
                        multiline
                        rows={4}
                        fullWidth
                        value={emails}
                        onChange={(e) => setEmails(e.target.value)}
                        variant="outlined"
                        sx={{ fontSize: '0.875rem', '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ color: '#417690', backgroundColor: '#EDF4F7', marginBottom: 2 }}
                        onClick={handleSendInvite}>
                            Send Invite
                    </Button>
                    <Button                        
                        sx={{ color: '#417690', backgroundColor: '#EDF4F7', marginBottom: 2 }}
                        onClick={handleCloseModal}>
                            Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
