import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Grid, Button, Box, TextField, TableCell, Container, IconButton, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { getData } from '../../ServerRequest';
import { Link } from 'react-router-dom';
const ProductEnquiries = () => {
    const [enquiries, setEnquiries] = useState([]);

    useEffect(() => {
        // Simulate fetching data from the backend
        async function fetchEnquiries() {

            let resData = await getData('/api/buyer/booking/');
            
            let bookingData = resData['data']['bookings']
            const response = [
                { bookingId: 'B123', enquiryComments: 'Need more details', requestSentTo: 'Customer A', postedOn: '2024-09-15 10:30 AM', status: 'Pending' },
                { bookingId: 'B456', enquiryComments: 'Check availability', requestSentTo: 'Customer B', postedOn: '2024-09-14 11:00 AM', status: 'Accepted' },
                { bookingId: 'B789', enquiryComments: 'Price negotiation', requestSentTo: 'Customer C', postedOn: '2024-09-13 09:45 AM', status: 'Rejected' },
            ];
            if (bookingData) {
                
                if (bookingData.length > 0) {
                    setEnquiries(bookingData);
                }
            } else {
                console.error("Error fetching buyers data:", resData.message);
            }
            //setLoading(false);
        }
       
        
        fetchEnquiries();
    }, []);

    const formatDateTime = (utcDateTime) => {
        const date = new Date(utcDateTime);

        // Convert to IST (UTC+5:30)
        const istDate = new Date(date.getTime() + 5.5 * 60 * 60 * 1000);

        // Format date and time
        const datePart = istDate.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
        const timePart = istDate.toLocaleTimeString("en-IN", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });

        return { date: datePart, time: timePart };
    };

    const handleAction = (action, bookingId) => {
        setEnquiries((prevEnquiries) =>
            prevEnquiries.map((enquiry) =>
                enquiry.bookingId === bookingId ? { ...enquiry, status: action } : enquiry
            )
        );
    };

    const handleDelete = (bookingId) => {
        setEnquiries((prevEnquiries) =>
            prevEnquiries.filter((enquiry) => enquiry.bookingId !== bookingId)
        );
    };

    const columns = [
        {
            name: "ps_book_id",
            label: "Booking ID",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return <Link style={{ color: '#0074cd', textDecoration: 'none' }} to={`/admin/products/enquiryDetail/?booking_id=${value}`}>
                            {value}
                           </Link>;
                  },
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            },
        },
        {
            name: "ps_book_buyerid",
            label: "Buyer ID",
            options: {
                filter: false,
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                // customBodyRender: (value) => (
                //     <TextField
                //         defaultValue={value}
                //         variant="outlined"
                //         fullWidth
                //         multiline
                //         minRows={2}
                //         disabled
                //     />
                // ),
            },
        },
        {
            name: "ps_book_codes",
            label: "Product Codes",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            },
        },
        {
            name: "ps_book_dt",
            label: "Posted On",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => {
                    if (!value) return "";
                    const { date, time } = formatDateTime(value);
                    return (
                        <span>
                            <strong>Date :</strong> {date} <br />
                            <strong>Time :</strong> {time}
                        </span>
                    );        
                },
            },
        },
        {
            name: "ps_book_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => {
                    let color;
                    if (value === 'Pending') color = 'orange';
                    else if (value === 'Accepted') color = 'green';
                    else if (value === 'Completed') color = 'blue';
                    else color = 'red';

                    return <span style={{ color }}>{value}</span>;
                },
            },
        },
        // {
        //     name: "actions",
        //     label: "Actions",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customHeadRender: (columnMeta) => (
        //             <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
        //                 {columnMeta.label}
        //             </TableCell>
        //         ),
        //         customBodyRender: (value, tableMeta) => {
        //             const bookingId = tableMeta.rowData[0]; // Get the bookingId from row data

        //             return (
        //                 <Box sx={{ display: 'flex', gap: 1 }}>
        //                     <IconButton
        //                         onClick={() => handleAction('Accepted', bookingId)}
        //                         color="success"
        //                         title="Accept"
        //                     >
        //                         <CheckCircleIcon />
        //                     </IconButton>
        //                     <IconButton
        //                         onClick={() => handleAction('Rejected', bookingId)}
        //                         color="error"
        //                         title="Reject"
        //                     >
        //                         <CancelIcon />
        //                     </IconButton>
        //                     <IconButton
        //                         onClick={() => handleAction('Completed', bookingId)}
        //                         color="primary"
        //                         title="Completed"
        //                     >
        //                         <DoneIcon />
        //                     </IconButton>
        //                     <IconButton
        //                         onClick={() => handleDelete(bookingId)}
        //                         color="error"
        //                         title="Delete"
        //                     >
        //                         <DeleteIcon />
        //                     </IconButton>
        //                 </Box>
        //             );
        //         },
        //     },
        // },
    ];

    const options = {
        sort: true,
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 8 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                <MUIDataTable
                    title={
                        <Typography variant="h6" component="div">
                             Product Enquiries : <span style={{ color: 'orange' }}> {enquiries.length} </span>
                        </Typography>
                    }
                    data={enquiries}
                    columns={columns}
                    options={options}
                />    
                </Box>
            </Grid>
        </Container>
    );
};

export default ProductEnquiries;
